<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            outlined
                                            dense
                                            v-model="date_from"
                                            label="Last Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            style="margin-top: 7px !important;"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account-tie"
                                        clearable
                                        dense
                                        solo
                                        v-model="customer"
                                        :items="customers"
                                        item-value="cust_id"
                                        :item-text="item => item.cust_name"
                                        label="Customer"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_customer"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="3" lg="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12 white--text" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon color="#fff">mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-1">
               <v-card class="pa-md-5" outlined>
                    <h6 class="cyan-text text-darken-3 m-0">Complain Summary</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="items"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="350"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Customer Complain </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Order',
                    disabled: true,
                },
                {
                    text: 'Customer Complain',
                    disabled: true,
                }
            ],
            modal: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            customer: '',
            customers: [],
            loading_customer: false,
            searchItem: '',
            headers: [],
            items: [],
            months: [],
            month_charts: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            x: window.matchMedia("(max-width: 991px)"),

        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        await this.getCustomer()
        this.$store.dispatch('setOverlay', false)  
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        async getCustomer(){
            this.loading_customer = true

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sm/production/loc/filter-customer`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_customer = false
                this.customers = res.data.data

            });
        },
        getMonth(start, value){
            this.months = []
            this.headers = []
            const monthString = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            for (let index = 0; index <= value; index++) {
                if (index == 0) {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    let name = monthString[startDate.getMonth()];
                    this.months.push({
                        value: start.replace(/-/g,""),
                        text: start
                    })
                } else {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    var startDayofMonth = new Date(startDate.getFullYear(), (startDate.getMonth() + 1) - index, 0);
                    // console.log(startDayofMonth);
                    var month = (new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
                    let name = monthString[(new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).getMonth()];

                    this.months.push(
                        {
                            value: month.replace(/-/g,""),
                            text: month
                        }    
                    )
                }                  

            }

            this.headers = [
                { text: 'Customer', value: 'cust_name', width:300, align: 'left' },
                { text: this.months[0].text, value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text, value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text, value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text, value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text, value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text, value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[6].text, value: this.months[6].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[7].text, value: this.months[7].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[8].text, value: this.months[8].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[9].text, value: this.months[9].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[10].text, value: this.months[10].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[11].text, value: this.months[11].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]


        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            this.getMonth(this.date_from, 12)

            var reqBody = {
                'cust_id': this.customer ? this.customer : '',
                'start_date': this.months.length > 0 ? this.months[11].value : '',
                'end_date': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months,
            }

            const respData = await backendApi.fetchCore('/api/v3/sm/production/loc/table', reqBody, false, false, false)
            const respDataChart = await backendApi.fetchCore('/api/v3/sm/production/loc', reqBody, false, false, false)
            if (respData.status === 200 && respDataChart.status === 200) {
                console.log(respDataChart.data.data.chart);
                this.items = respData.data
                var dt = []
                for (var i = 0; i < respDataChart.data.data.chart.length; i++) {
                    dt.push({ label: respDataChart.data.data.chart[i].label, y: parseFloat(respDataChart.data.data.chart[i].y)});
                }

                this.getChart2(dt, 'container1')

                this.$store.dispatch('setOverlay', false)  
            }

        },
        getChart2(dt1, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "KG",
                        labelFormatter: this.addSymbols,
                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: " Complain Weight (KG)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#A0DBAF",
                },
            ]
            });

            chart.options.data[0].dataPoints = dt1;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        monthNumToName(monthnum) {
            return this.month_charts[monthnum - 1] || '';
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
    },    
}
</script>